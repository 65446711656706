import LocalStorageManager from "./LocalStorageManager";

class UserInteractionManager {
  private static readonly DOWNLOAD_KEY = "resourceDownloadClicked";

  public clickedDownload(link: string): void {
    LocalStorageManager.set(UserInteractionManager.DOWNLOAD_KEY, link);
  }

  private openDownloadUrl(url: string): void {
    // TO DO: Handle for Popup blocked after Google Sign in.
    window.open(url);
  }

  public handlePostAuthDownload(): void {
    const downloadLink = LocalStorageManager.get(
      UserInteractionManager.DOWNLOAD_KEY
    );

    if (downloadLink) {
      LocalStorageManager.remove(UserInteractionManager.DOWNLOAD_KEY);
      this.openDownloadUrl(downloadLink);
    }
  }
}

export default UserInteractionManager;
