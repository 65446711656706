const { gql } = require("@apollo/client");

const GET_USER_SUBSCRIPTIONS = gql`
  query GetUserSubscriptions {
    getUserSubscriptions {
      currentStatus {
        status
        createdAt
        updatedAt
      }
      type
      user
      products
    }
  }
`;

export { GET_USER_SUBSCRIPTIONS };
