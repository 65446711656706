import Queue from "yocto-queue";

class TaskQueue {
  private taskQueue: Queue<{ task: Function; params: any[] }> = new Queue();
  public isRunning: boolean = false;

  public enqueueTask(task: Function, ...params: any[]): void {
    this.taskQueue.enqueue({ task, params });
  }

  private async consume(): Promise<void> {
    setTimeout(async () => {
      while (this.taskQueue.size > 0) {
        const { task, params } = this.taskQueue.dequeue()!;
        try {
          if (task instanceof Function) {
            await task(...params);
          }
        } catch (error) {
          console.error("Error executing task:", error);
        }
      }
    }, 100);
  }

  public async startConsume(): Promise<void> {
    if (this.isRunning) return;

    this.isRunning = true;
    await this.consume();
    this.isRunning = false;
  }
}

export default TaskQueue;
