import { useDispatch, useSelector } from "react-redux";
import { setAuthToken, clearAuthToken } from "@/store/reducers/authReducer";
import nookies from "nookies";
import ClientAppManager from "@/Logic/ClientLogic/Services/ClientAppManager";
import useUserActions from "./UserProfileActions/userAction";
import useUserCourseProgressActions from "./userCourseProgressAction";
import useUserCourseStatusAction from "./userCourseStatusAction";
import useLmsProgressActions from "./lmsProgressAction";
const USER_DATA_KEY = "userData";

const useAuthActions = () => {
  const { clearDetails } = useUserActions();
  const { clearUserCourseProgress } = useUserCourseProgressActions();
  const { clearCourseStatusDetails } = useUserCourseStatusAction();
  const { clearLMSProgress } = useLmsProgressActions();

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const setToken = async (token) => {
    localStorage.setItem(USER_DATA_KEY, JSON.stringify({ token }));
    const response = await fetch(window.origin + "/api/parse-token", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const { decoded = {} } = (await response.json()) || {};

    localStorage.setItem(USER_DATA_KEY, JSON.stringify({ ...decoded, token }));
    nookies.set(null, "authToken", token, {
      maxAge: 365 * 24 * 60 * 60,
      path: "/",
    });
    dispatch(setAuthToken(token));
    await ClientAppManager.instance.userDetailsManager.setTokenCallback(token);
  };
  const clearToken = () => {
    nookies.destroy(null, "authToken", {
      path: "/",
    });
    localStorage.removeItem("authToken");
    dispatch(clearAuthToken());
    ClientAppManager.instance.userDetailsManager.setTokenCallback(null);
  };

  const clearAuthStates = () => {
    clearDetails();
    clearCourseStatusDetails();
    clearLMSProgress();
    clearUserCourseProgress();
  };

  return { setToken, clearToken, auth, clearAuthStates };
};

export default useAuthActions;
