import AskFillWaitlistFormPopupActionController from "../FlowActionControllers/PopupActionController/AskFillWaitlistFormPopupAction.controller";
import ExperiencedJoinReasonFormPageActionController from "../FlowActionControllers/PageActionController/ExperiencedJoinReasonFormPageAction.controller";
import ExperienceFormPageActionController from "../FlowActionControllers/PageActionController/ExperienceFormPageAction.controller";
import FresherJoinReasonFormPageActionController from "../FlowActionControllers/PageActionController/FresherJoinReasonFormPageAction.controller";
import GamingExperienceFormPageActionController from "../FlowActionControllers/PageActionController/GamingExperienceFormPageAction.controller";
import IsWorkingFormPageActionController from "../FlowActionControllers/PageActionController/IsWorkingFormPageAction.controller";
import JobCategoryFormPageActionController from "../FlowActionControllers/PageActionController/JobCategoryFormPageAction.controller";
import NameFormPageActionController from "../FlowActionControllers/PageActionController/NameFormPageAction.controller";
import PhoneNumberFormPageActionController from "../FlowActionControllers/PageActionController/PhoneNumberFormPageAction.controller";
import PreferredJobCategoryFormPageActionController from "../FlowActionControllers/PageActionController/PreferredJobCategoryFormPageAction.controller";
import BaseFlowController from "./Baseflow.controller";
import { AskFillWaitlistFormData } from "./FlowControllers.model";
import ClientAppManager from "../../ClientAppManager";
import { CALENDLY_ADMISSION_BOOKING_POPUP } from "@/store/actions/popupAction";
import DiscordPageActionController from "../FlowActionControllers/PageActionController/DiscordPageAction.controller";
import { FlowDataType } from "../UserFlowService.types";
import BookCallPageActionController from "../FlowActionControllers/PageActionController/BookCallPageAction.controller";
import WaitlistConfirmationPageActionController from "../FlowActionControllers/PageActionController/WaitistConfirmationPageAction.controller";
import ReturnOriginPageActionController from "../FlowActionControllers/PageActionController/ReturnOriginPageAction.controller";
import PopupService from "../../PopupService";

class ReturningWaitlistedUserFlowController extends BaseFlowController {
  protected actions = [
    new AskFillWaitlistFormPopupActionController<ReturningWaitlistedUserFlowController>(
      this,
      AskFillWaitlistFormData
    ),
    new NameFormPageActionController<ReturningWaitlistedUserFlowController>(
      this
    ),
    new PhoneNumberFormPageActionController<ReturningWaitlistedUserFlowController>(
      this
    ),
    new ExperienceFormPageActionController<ReturningWaitlistedUserFlowController>(
      this
    ),
    new GamingExperienceFormPageActionController<ReturningWaitlistedUserFlowController>(
      this
    ),
    new JobCategoryFormPageActionController<ReturningWaitlistedUserFlowController>(
      this
    ),
    new PreferredJobCategoryFormPageActionController<ReturningWaitlistedUserFlowController>(
      this
    ),
    new IsWorkingFormPageActionController<ReturningWaitlistedUserFlowController>(
      this
    ),
    new FresherJoinReasonFormPageActionController<ReturningWaitlistedUserFlowController>(
      this
    ),
    new ExperiencedJoinReasonFormPageActionController<ReturningWaitlistedUserFlowController>(
      this
    ),
    new DiscordPageActionController<ReturningWaitlistedUserFlowController>(
      this
    ),
    new WaitlistConfirmationPageActionController<ReturningWaitlistedUserFlowController>(
      this
    ),
    new ReturnOriginPageActionController<ReturningWaitlistedUserFlowController>(
      this
    ),
  ];

  constructor(flowData: FlowDataType) {
    super(flowData);
  }

  private checkIfWaitlistProfileCompleted = () => {
    const userDetails = ClientAppManager.instance.userDetailsManager.user;

    const { userJobProfile, userPreference } = userDetails;

    const userWaitlistProfileCompleted =
      userDetails?.userDetails?.discord?.id &&
      userJobProfile?.name &&
      userJobProfile?.experience != null &&
      userJobProfile?.experience >= 0 &&
      userJobProfile?.gaming_job_experience != null &&
      userJobProfile?.gaming_job_experience >= 0 &&
      userJobProfile?.phone_number &&
      userJobProfile?.user_form_data?.is_currently_working != undefined &&
      (userJobProfile?.job_category || userPreference?.job_category) &&
      userJobProfile?.user_form_data?.course_joining_reason;

    return userWaitlistProfileCompleted ? true : false;
  };

  override isFlowRequired(): boolean {
    let shouldStartFlow = true;

    const flowBreakers =
      ClientAppManager.instance.userFlowManager.router.asPath.includes(
        `popup=${CALENDLY_ADMISSION_BOOKING_POPUP}`
      ) ||
      ClientAppManager.instance.userFlowManager.router.asPath.includes(
        `community/subscriptions`
      )
        ? true
        : false;

    let lastAsked = localStorage.getItem("askFillWaitlistFormOpened");

    if (lastAsked) {
      let lastAskedTime = new Date(JSON.parse(lastAsked));
      let currentTime = new Date();
      let timeDiff =
        (currentTime.getTime() - lastAskedTime.getTime()) / (1000 * 60 * 60);

      if (timeDiff < 24) {
        shouldStartFlow = false;
      }
    }

    let isWaitlistProfileCompleted = this.checkIfWaitlistProfileCompleted();

    return !flowBreakers && shouldStartFlow && !isWaitlistProfileCompleted
      ? true
      : false;
  }

  public preFlowStartSetup(): void {
    localStorage.setItem(
      "prev-url",
      PopupService.instance.removePopupQueryFromUrl(
        ClientAppManager.instance.userFlowManager.router.asPath
      )
    );
  }
}

export default ReturningWaitlistedUserFlowController;
