import PosthogController from "./PosthogController";
import UTMManager from "../UTMManager";
import UserDetailsManager from "../UserDetailsManager";
import ClientAppManager from "../ClientAppManager";
import ClarityController from "./ClarityController";
import TaskQueue from "@/Services/TaskQueue";

class AnalyticsService {
  public userDetailsManager: UserDetailsManager;

  private initialised: boolean = false;
  private taskQueue: TaskQueue = new TaskQueue();
  private userLoaded: boolean = false;
  private posthogController: PosthogController;
  private clarityController: ClarityController;
  private capturedEvents: Set<string> = new Set();

  constructor(userDetailsManager: UserDetailsManager) {
    this.userDetailsManager = userDetailsManager;
    this.posthogController = new PosthogController(userDetailsManager);
    this.clarityController = new ClarityController();
  }

  public loaded(userLoaded: boolean, posthog) {
    if (posthog) {
      this.posthogController.posthog = posthog;
    }

    if (userLoaded) {
      this.userLoaded = userLoaded;
    }
    console.log(
      "[AnalyticsService] userloaded",
      userLoaded,
      "posthog :",
      this.posthogController.posthog ? "true" : "false",
      this.initialised
    );

    this.initialised =
      (this.posthogController.posthog?.__loaded && this.userLoaded) ||
      this.initialised;
    if (this.initialised) {
      this.taskQueue.startConsume();
    }
  }

  public identifyUser(email: string) {
    if (!this.initialised) {
      this.taskQueue.enqueueTask(this.identifyUser.bind(this), email);
      return;
    }
    console.log("[AnalyticsService] identifyUser");
    ClientAppManager.instance.userDetailsManager.user.userDetails.email =
      email ||
      ClientAppManager.instance.userDetailsManager.user.userDetails.email;

    this.posthogController.identifyUser(this.userDetailsManager, {
      LandingIntent: UTMManager?.instance?.landingIntent?.intent,
    });

    this.clarityController.identifyUser(this.userDetailsManager, {
      LandingIntent: UTMManager?.instance?.landingIntent?.intent,
    });
  }

  public startSessionRecording() {
    if (!this.initialised) {
      this.taskQueue.enqueueTask(this.startSessionRecording.bind(this));
      return;
    }
    this.posthogController.startSessionRecording();
    console.log("[AnalyticsService] startSessionRecording");
  }

  public captureEvent(eventName: string, data: { [key: string]: number }) {
    if (!this.initialised) {
      this.taskQueue.enqueueTask(this.captureEvent.bind(this), eventName, data);
      return;
    }

    // this.posthogController.captureEvent(eventName, data);
  }

  public captureUserEvent(data: { [key: string]: number }) {
    if (!this.initialised) {
      this.taskQueue.enqueueTask(this.captureUserEvent.bind(this), data);
      return;
    }

    this.throttleEvent(data, "USER_EVENT", () => {
      // console.log("[AnalyticsService] captureUserEvent", data);
      this.posthogController.captureUserEvent(data);
      this.clarityController.captureUserEvent(data);
    });
  }

  private throttleEvent(data, eventKey, callback) {
    const dataString = JSON.stringify(data) + ` ${eventKey}`;
    if (this.capturedEvents.has(dataString)) {
      return;
    }
    callback();
    this.capturedEvents.add(dataString);
  }
}

export default AnalyticsService;
