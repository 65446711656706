export const UserSubscriptionStatusEnum = {
  ACTIVE: "ACTIVE",
  CANCELLED: "CANCELLED",
  "CANCELLATION-IN-PROGRESS": "CANCELLATION-IN-PROGRESS",
};

export const UserSubscriptionTypeEnum = {
  "PROJECTS-SUBSCRIPTION-LIFETIME": "PROJECTS-SUBSCRIPTION-LIFETIME",
  FREE_TRIAL_SUBSCRIPTION: "FREE_TRIAL_SUBSCRIPTION",
};

export const UserSubscriptionSourceEnum = {
  ADMIN_PANEL: "ADMIN_PANEL",
  USER_PAYMENT: "USER_PAYMENT",
  FREE_TRIAL: "FREE_TRIAL",
};
