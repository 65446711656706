import ApolloClientManager from "@/Logic/GraphQL/ApolloClientManager";
import { GET_USER_SUBSCRIPTIONS } from "@/Logic/GraphQL/Queries/userSubscription.query";

class UserSubscriptionActionController {
  async getUserSubscriptions() {
    let res = await ApolloClientManager.client.query({
      query: GET_USER_SUBSCRIPTIONS,
    });

    return res.data.getUserSubscriptions;
  }
}

export default new UserSubscriptionActionController();
