import BaseEnum from "./BaseEnum";
const CourseLeadSourceEnum = new BaseEnum(
  [
    "ads-page",
    "courses-leads-form",
    "full-stack-game-development",
    "game-development-fast-track",
    "game-development-advance",
    "unity-game-dev-scholarship-program",
    "advanced-game-development-in-unity",
    "data-structure-intermediate",
    "data-structure-advanced",
    "intermediate-cpp",
    "career-advisor-form",
    "join-waitlist",
    "pricing-section",
    "scholarship-application-started",
    "waitlist-card",
    "lms-footer",
    "payment-page",
    "free-trial",
  ],
  [
    "Ads Page",
    "Courses Leads Form",
    "Full Stack Game Development",
    "Game Development Fast Track",
    "Game Development Advance",
    "Unity Game Dev Scholarship Program",
    "Advanced Game Development in Unity (Scholarship)",
    "Data Structure Intermediate Course",
    "Master Data Structures and Algorithms Course with Gamified Learning",
    "Pokemon",
    "Career Advisor Form",
    "Join Waitlist",
    "Pricing Section",
    "Scholarship Application Started",
    "Waitlist Card",
    "LMS Footer",
    "Payment Page",
    "Free Trial",
  ]
);
export default CourseLeadSourceEnum;
