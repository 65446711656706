import ClientAppManagerInterface from "./ClientAppManagerInterface";
import LocalStorageManager from "./LocalStorageManager";
import { UserType } from "./Service.types";

class UserDetailsManager {
  public user: UserType = { userDetails: {} } as UserType;
  public anonymousUserId: string;
  public token: string;
  private isInitialised: boolean = false;
  private clientAppManager: ClientAppManagerInterface;
  constructor(t: ClientAppManagerInterface) {
    this.clientAppManager = t;
    let data = JSON.parse(LocalStorageManager.get("userData"));
    if (data?.email) {
      this.token = data?.token;
      this.user.userDetails.email = data.email;
    }
    let email = LocalStorageManager.get("email");
    if (email) {
      this.user.userDetails.email = email;
    }
    this.anonymousUserId = LocalStorageManager.get("__anon_id")
      ? JSON.parse(LocalStorageManager.get("__anon_id"))
      : "";
  }

  private onInitialise = () => {
    this.clientAppManager.checkTriggerFlowOnDetailsInitialised();
  };

  public initialise(): void {
    if (!this.isInitialised) {
      this.isInitialised = true;
      this.onInitialise();
    }
  }

  public setUserData(user: UserType): void {
    this.user = { ...this.user, ...user };
  }

  public async setTokenCallback(token: string): Promise<void> {
    try {
      this.token = token;
      if (token) {
        await this.clientAppManager.assessmentsManager.submitPendingAssessment();
        await this.clientAppManager.jobAlertsManager.createPostAuthAlert();
        this.clientAppManager.userInteractionManager.handlePostAuthDownload();
      } else {
        console.error("No auth token available to execute the callback.");
      }
    } catch (error) {
      console.error("Error executing token callback action:", error);
    }
  }
}

export default UserDetailsManager;
