import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setCourseProgress,
  clearCourseProgress,
} from "@/store/reducers/userCourseProgressReducer";

import NetworkManager from "@/outscal-commons-frontend/Managers/NetworkManager";

var apiInProgress = false;

const useUserCourseProgressActions = () => {
  const dispatch = useDispatch();
  const userCourseProgress = useSelector((state) => state.userCourseProgress);

  const setUserCourseProgress = (progress) => {
    dispatch(setCourseProgress(progress));
  };
  const clearUserCourseProgress = () => {
    dispatch(clearCourseProgress());
  };

  const getUserCourseProgress = async () => {
    apiInProgress = true;
    const resp = await NetworkManager.get("/user-course-progress");
    setUserCourseProgress(resp.data);
    apiInProgress = false;
  };

  useEffect(() => {
    if (!userCourseProgress.data && !apiInProgress) {
      getUserCourseProgress();
    }
  }, []);
  return {
    setUserCourseProgress,
    clearUserCourseProgress,
    userCourseProgress,
  };
};

export default useUserCourseProgressActions;
