import UserAccessTypeEnum from "@/outscal-commons-frontend/Enums/userAccessTypeEnum";
import LazySingleton from "@/utils/DesignPatternHelpers/LazySingleton";
import ProUserCourse from "./ProUserCourse.controller";
import DefaultUserCourse from "./DefaultUserCourse.controller";

class UserCourseService extends LazySingleton {
  constructor() {
    super();
    this.userCourseController;
    this.course = {};
    this.courseFromStrapi = {};
  }

  setupCourse(course = {}, courseFromStrapi = {}) {
    this.course = course;
    this.courseFromStrapi = courseFromStrapi;
  }

  setUpUserCourseController = (user) => {
    let isProUser =
      user?.userDetails?.user_access?.type ==
      UserAccessTypeEnum.getValue("pro-trial-user");

    if (isProUser) {
      this.userCourseController = new ProUserCourse(
        this.course,
        user,
        this.courseFromStrapi
      );
    } else {
      this.userCourseController = new DefaultUserCourse(
        this.course,
        user,
        this.courseFromStrapi
      );
    }
  };

  checkUserEnrolledInCurrCourse = (user) => {
    return user?.userCourseStatus?.some(
      (courseStatus) => courseStatus?.course?.slug === this.course?.slug
    );
  };
}

export default UserCourseService;
