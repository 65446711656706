import { createSlice } from "@reduxjs/toolkit";

const codeEditorSlice = createSlice({
  name: "codeEditor",
  initialState: {
    codeEditor: null,
    codeCompilerTab: "input",
    isFullscreen: false,
    isLoading: false,
    interactiveInput: false,
    codeResponse: {},
  },
  reducers: {
    setEditor: (state, action) => {
      state.codeEditor = action.payload;
      return state;
    },
    setTab: (state, action) => {
      state.codeCompilerTab = action.payload;
      return state;
    },
    setFullscreen: (state, action) => {
      state.isFullscreen = action.payload;
      return state;
    },
    setInteractiveInput: (state, action) => {
      state.interactiveInput = action.payload;
      return state;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
      return state;
    },
    setResponse: (state, action) => {
      state.codeResponse = action.payload;
      return state;
    },
    reset: (state, action) => {
      state = {
        code: action.payload.defaultValue,
        codeCompilerTab: "input",
        input: "",
        isFullscreen: false,
        isLoading: false,
        codeResponse: {},
      };
      return state;
    },
  },
});

export const {
  setEditor,
  setFullscreen,
  setIsLoading,
  setResponse,
  setTab,
  reset,
  setInteractiveInput,
} = codeEditorSlice.actions;

export default codeEditorSlice.reducer;
