import { createSlice } from "@reduxjs/toolkit";

const userCourseProgressSlice = createSlice({
  name: "userCourseProgress",
  initialState: { data: null },
  reducers: {
    setCourseProgress: (state, action) => {
      state.data = action.payload;
      return state;
    },
    clearCourseProgress: (state) => {
      state = { data: null };
      return state;
    },
  },
});

export const { setCourseProgress, clearCourseProgress } =
  userCourseProgressSlice.actions;
export default userCourseProgressSlice.reducer;
