import BaseFlowController from "../../FlowControllers/Baseflow.controller";
import BasePageActionController from "./BasePageAction.controller";

class SetJobAlertsActionController<
  T extends BaseFlowController
> extends BasePageActionController<T> {
  protected url = "/welcome/ftueJobAlerts";
  override checkAction(): boolean {
    return true;
  }
}

export default SetJobAlertsActionController;
