import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setUserDetails,
  setUserPreference,
  setUserProfile,
  setUserResume,
  setUserCourseStatus,
  setUserNotifications,
  clearUserDetails,
  setUserScholarships,
  setUserSubscriptions,
} from "../../reducers/userReducer";
import {
  AnalyticsManager,
  NetworkManager,
} from "@/outscal-commons-frontend/Managers";
import userScholarshipActionController from "./userScholarshipAction.controller";
import UserCourseStatusAction from "./userCourseStatusAction";
import ClientAppManager from "@/Logic/ClientLogic/Services/ClientAppManager";
import UserSubscriptionActionController from "./userSubscriptionAction.controller";
import {
  UserSubscriptionStatusEnum,
  UserSubscriptionTypeEnum,
} from "@/outscal-commons-frontend/Enums/UserSubscriptionEnum";
import {
  RegistrationTypeEnum,
  UserCourseStatusEnum,
} from "@/outscal-commons-frontend/Enums";
const USER_DATA_KEY = "userData";
var apiInProgress = false;
const useUserActions = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const setProfile = (profile) => {
    dispatch(setUserProfile(profile));

    ClientAppManager.instance.userDetailsManager.setUserData({
      userJobProfile: {
        name: profile?.name,
        calling_code: profile?.calling_code,
        country: profile?.country,
        persona_v3: profile?.persona_v3,
        phone_number: profile?.phone_number,
        is_working_professional: profile?.is_working_professional,
        experience: profile?.experience,
        gaming_job_experience: profile?.gaming_job_experience,
        job_category: profile?.job_category,
        user_form_data: {
          is_currently_working: profile?.user_form_data?.is_currently_working,
          course_joining_reason: profile?.user_form_data?.course_joining_reason,
        },
      },
    });
    ClientAppManager.instance.analyticsService.identifyUser();
  };

  const clearDetails = () => {
    dispatch(clearUserDetails());
    ClientAppManager.instance.userDetailsManager.setUserData({
      userJobProfile: {},
      userCourseStatuses: [],
      userDetails: {},
      userPreference: {},
    });
  };

  const setPreference = (preference) => {
    dispatch(setUserPreference(preference));

    ClientAppManager.instance.userDetailsManager.setUserData({
      userPreference: {
        job_category: preference?.job_category,
        assessment_skills: preference?.assessment_skills,
      },
    });
  };

  const setResume = (resume) => {
    dispatch(setUserResume(resume));
  };

  const setDetails = (details) => {
    dispatch(setUserDetails(details));

    ClientAppManager.instance.userDetailsManager.setUserData({
      userDetails: {
        email: details?.email,
        _id: details?._id,
        profile_picture: details?.profile_picture,
        user_access: details?.user_access,
        user_role: details?.user_role,
        username: details?.username,
        discord: {
          username: details?.discord?.username,
          id: details?.discord?.id,
        },
      },
    });
  };

  const setCourseStatus = (courseStatus) => {
    dispatch(setUserCourseStatus(courseStatus));

    ClientAppManager.instance.userDetailsManager.setUserData({
      userCourseStatuses: courseStatus?.map((status) => {
        return {
          _id: status._id,
          current_status: {
            status: status?.current_status?.status,
          },
          course: {
            slug: status?.course?.slug,
            _id: status?.course?._id,
          },
        };
      }),
    });
  };

  const setScholarships = (scholarships) => {
    dispatch(setUserScholarships(scholarships));
  };

  const setNotifications = (notifications) => {
    dispatch(setUserNotifications(notifications));
  };

  const setSubscriptions = (subscriptions) => {
    dispatch(setUserSubscriptions(subscriptions));
    ClientAppManager.instance.userDetailsManager.setUserData({
      userSubscriptions: subscriptions,
    });
  };

  const updateUserProfile = async (showLoading = false) => {
    apiInProgress = true;
    let resp = await NetworkManager.get(
      "/users/profile",
      undefined,
      undefined,
      showLoading
    );
    const savedProfile = resp?.data?.profile;
    AnalyticsManager.event({
      category: "UserPersona",
      eventName: "",
      action: savedProfile?.persona >= 0 ? savedProfile.persona : -1,
    });

    AnalyticsManager.setUserProps(savedProfile);

    if (resp.data) {
      if (resp.data.profile) {
        setProfile(resp.data.profile);
      }
      if (resp.data.preference) {
        setPreference(resp.data.preference);
      }
      if (resp.data.resume) {
        setResume(resp.data.resume);
      }
      if (resp.data?.details?.data) {
        setDetails(resp.data.details.data);
      }
      if (resp?.data?.userCourseStatus) {
        setCourseStatus(resp.data.userCourseStatus);
      }
      if (resp?.data?.notifications) {
        setNotifications(resp?.data?.notifications);
      }
      if (resp?.data?.userScholarships) {
        setScholarships(resp?.data?.userScholarships);
      }
      if (resp?.data?.userSubscriptions) {
        setSubscriptions(resp?.data?.userSubscriptions);
      }
    }
    apiInProgress = false;
  };

  const getAndSetUserProfile = async () => {
    if (localStorage.getItem(USER_DATA_KEY)) {
      await updateUserProfile();
      ClientAppManager.instance.userDetailsManager.initialise();
    }
  };

  const getAndSetUserScholarships = async () => {
    const userScholarships =
      await userScholarshipActionController.getUserScholarships();
    if (userScholarships) {
      setScholarships(userScholarships);
    }
  };

  const getAndSetUserCourseStatuses = async () => {
    const userCourseStatuses =
      await UserCourseStatusAction.getUserCoursStatuses();

    if (userCourseStatuses?.length > 0) {
      setCourseStatus(userCourseStatuses);
    }
  };

  const hasEnrolledInPaidCourses = (data) => {
    return data?.find(
      (courseStatus) =>
        courseStatus.current_status?.status ==
          UserCourseStatusEnum.getValue("access-granted") ||
        courseStatus.current_status?.status ==
          UserCourseStatusEnum.getValue("started")
    )
      ? true
      : false;
  };

  const getAndSetUserSubscriptions = async () => {
    const userSubscriptions =
      await UserSubscriptionActionController.getUserSubscriptions();

    if (userSubscriptions?.length > 0) {
      setSubscriptions(userSubscriptions);
    }
  };

  const getAndSetLogoutUserProfile = () => {
    let logoutUserProfile = JSON.parse(
      sessionStorage.getItem("logout-user-profile") || "{}"
    );

    if (logoutUserProfile.email) {
      setProfile(logoutUserProfile.profile);
      setPreference(logoutUserProfile.preference);
    }
    ClientAppManager.instance.userDetailsManager.initialise();
  };

  const getActiveCourseSubscription = (data) => {
    return data?.userSubscriptions?.find(
      (item) =>
        (item?.type ==
          UserSubscriptionTypeEnum["PROJECTS-SUBSCRIPTION-LIFETIME"] ||
          item?.type == UserSubscriptionTypeEnum.FREE_TRIAL_SUBSCRIPTION) &&
        item?.currentStatus?.status === UserSubscriptionStatusEnum["ACTIVE"]
    );
  };

  useEffect(() => {
    if (!user.userDetails && !apiInProgress) {
      getAndSetUserProfile();
    }
  }, []);

  useEffect(() => {
    const currentCourseStatus = () => {
      const enrolled = hasEnrolledInPaidCourses(user?.userCourseStatus);
      const subscribed = !!getActiveCourseSubscription(user);

      if (enrolled && subscribed) return "Subscribe&Enrolled";
      if (subscribed) return "SubscribedOnly";
      if (enrolled) return "EnrolledOnly";
      return "None";
    };
    if (
      ClientAppManager.instance.userDetailsManager.token &&
      user.userDetails
    ) {
      ClientAppManager.instance.analyticsService.loaded(true);
      ClientAppManager.instance.analyticsService.captureUserEvent({
        Enrolled: hasEnrolledInPaidCourses(user?.userCourseStatus)
          ? "YES"
          : "NO",
        Subscribed: getActiveCourseSubscription(user) ? "YES" : "NO",
        CurrentCourseStatus: currentCourseStatus(),
        PhoneNumber: user?.userJobProfile?.phone_number ? "YES" : "NO",
        user_id: user.userDetails?._id || "-",
        LoggedIn: "YES",
        TestUser:
          user.userDetails?.email?.includes("@outscal") ||
          user.userDetails?.email?.includes("test")
            ? "YES"
            : "NO",
        GuestAccount:
          user.userDetails?.registrationType ==
          RegistrationTypeEnum.getValue("guest-account")
            ? "YES"
            : "NO",
        RegistrationIntent: user.userDetails?.intent?.intent?.intent || "-",
      });
    } else if (!ClientAppManager.instance.userDetailsManager.token) {
      ClientAppManager.instance.analyticsService.loaded(true);
      ClientAppManager.instance.analyticsService.captureUserEvent({
        user_id: "notsignedin",
        LoggedIn: "NO",
      });
    }
  }, [user]);

  return {
    updateUserProfile,
    setProfile,
    setPreference,
    setResume,
    getAndSetUserProfile,
    setDetails,
    clearDetails,
    setCourseStatus,
    setNotifications,
    setScholarships,
    getAndSetUserScholarships,
    getAndSetUserCourseStatuses,
    user,
    getAndSetLogoutUserProfile,
    getAndSetUserSubscriptions,
    getActiveCourseSubscription,
  };
};

export default useUserActions;
