import { PostHog } from "posthog-js";
import UTMManager from "../UTMManager";
import TaskQueue from "@/Services/TaskQueue";
import UserDetailsManager from "../UserDetailsManager";

class PosthogController {
  public posthog: PostHog;
  public userDetailsManager: UserDetailsManager;

  constructor(userDetailsManager: UserDetailsManager) {
    this.userDetailsManager = userDetailsManager;
  }

  public loaded() {
    if (
      this.userDetailsManager?.user?.userDetails?.email?.includes(
        "@outscal.com"
      ) ||
      this.userDetailsManager?.user?.userDetails?.email?.includes("test")
    ) {
      this.stopRecording();
      return;
    }
  }
  private stopRecording() {
    try {
      if (this.posthog) {
        this.posthog?.opt_out_capturing();
        this.posthog?.stopSessionRecording();
        this.posthog = undefined;
      }
    } catch (error) {
      console.log("[PosthogController]", error);
    }
  }
  public identifyUser(
    userDetailsManager: UserDetailsManager,
    data?: { [key: string]: string | number }
  ) {
    if (
      userDetailsManager?.user?.userDetails?.email?.includes("@outscal.com") ||
      userDetailsManager?.user?.userDetails?.email?.includes("test")
    ) {
      this.stopRecording();
      return;
    }
    this.posthog?.identify(userDetailsManager.anonymousUserId, {
      email:
        userDetailsManager?.user?.userDetails?.email ||
        userDetailsManager?.anonymousUserId,
      anonymousUserId: userDetailsManager.anonymousUserId,
      sessionLandingIntent:
        UTMManager?.instance?.sessionIntent?.url.split("?")[0],
      ...UTMManager.instance.sessionUtm,
      firstLandingIntent:
        UTMManager.instance?.landingIntent?.url?.split("?")[0] ||
        userDetailsManager?.anonymousUserId,
      ...(data || {}),
      version: 3,
    });
  }

  public captureUserEvent(data: { [key: string]: string | number }) {
    // console.log("[AnalyticsService]posthog captureUserEvent", data);
    this.posthog?.capture("$set", { $set: { ...data } });
  }

  public captureEvent(
    eventName: string,
    data: { [key: string]: string | number }
  ) {
    console.log("[AnalyticsService]posthog captureEvent", eventName, data);
    this.posthog?.capture(eventName, { ...data });
  }

  public startSessionRecording() {
    this.posthog?.startSessionRecording();
  }
}

export default PosthogController;
