import ConnectDiscordActionController from "../FlowActionControllers/PageActionController/ConnectDiscordAction.controller";
import ReturnOriginPageActionController from "../FlowActionControllers/PageActionController/ReturnOriginPageAction.controller";
import SetJobAlertsActionController from "../FlowActionControllers/PageActionController/SetJobAlertsAction.controller";
import ShowCongratsPopupActionController from "../FlowActionControllers/ShowCongratsPopupAction.controller";
import { FlowDataType } from "../UserFlowService.types";
import BaseFlowController from "./Baseflow.controller";

class Ftue1SubmitFlowController extends BaseFlowController {
  protected actions = [
    new SetJobAlertsActionController<Ftue1SubmitFlowController>(this),
    new ConnectDiscordActionController<Ftue1SubmitFlowController>(this),
    new ReturnOriginPageActionController<Ftue1SubmitFlowController>(
      this
    ),
    new ShowCongratsPopupActionController<Ftue1SubmitFlowController>(this, {}),
  ];

  constructor(flowData: FlowDataType) {
    super(flowData);
  }
}

export default Ftue1SubmitFlowController;
