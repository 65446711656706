import ClientAppManager from "../../../ClientAppManager";
import BaseFlowController from "../../FlowControllers/Baseflow.controller";
import BasePageActionController from "./BasePageAction.controller";

class ConnectDiscordActionController<
  T extends BaseFlowController
> extends BasePageActionController<T> {
  protected url = "/welcome/ftueDiscord";

  public override checkAction(): boolean {
    return !ClientAppManager.instance.userDetailsManager?.user?.userDetails
      ?.discord?.id
      ? true
      : false;
  }
}

export default ConnectDiscordActionController;
