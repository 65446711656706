import { flowExcludeCourses } from "@/staticData/constant";
import ClientAppManager from "../../ClientAppManager";
import BaseFlowController from "./Baseflow.controller";
import NameFormPageActionController from "../FlowActionControllers/PageActionController/NameFormPageAction.controller";
import PhoneNumberFormPageActionController from "../FlowActionControllers/PageActionController/PhoneNumberFormPageAction.controller";
import ExperienceFormPageActionController from "../FlowActionControllers/PageActionController/ExperienceFormPageAction.controller";
import GamingExperienceFormPageActionController from "../FlowActionControllers/PageActionController/GamingExperienceFormPageAction.controller";
import JobCategoryFormPageActionController from "../FlowActionControllers/PageActionController/JobCategoryFormPageAction.controller";
import PreferredJobCategoryFormPageActionController from "../FlowActionControllers/PageActionController/PreferredJobCategoryFormPageAction.controller";
import IsWorkingFormPageActionController from "../FlowActionControllers/PageActionController/IsWorkingFormPageAction.controller";
import FresherJoinReasonFormPageActionController from "../FlowActionControllers/PageActionController/FresherJoinReasonFormPageAction.controller";
import ExperiencedJoinReasonFormPageActionController from "../FlowActionControllers/PageActionController/ExperiencedJoinReasonFormPageAction.controller";
import DiscordPageActionController from "../FlowActionControllers/PageActionController/DiscordPageAction.controller";
import { FlowDataType } from "../UserFlowService.types";
import BookCallPageActionController from "../FlowActionControllers/PageActionController/BookCallPageAction.controller";
import WaitlistConfirmationPageActionController from "../FlowActionControllers/PageActionController/WaitistConfirmationPageAction.controller";
import ReturnOriginPageActionController from "../FlowActionControllers/PageActionController/ReturnOriginPageAction.controller";
import PopupService from "../../PopupService";

class JoinWaitlistFlowController extends BaseFlowController {
  protected actions = [
    new NameFormPageActionController<JoinWaitlistFlowController>(this),
    new PhoneNumberFormPageActionController<JoinWaitlistFlowController>(this),
    new ExperienceFormPageActionController<JoinWaitlistFlowController>(this),
    new GamingExperienceFormPageActionController<JoinWaitlistFlowController>(
      this
    ),
    new JobCategoryFormPageActionController<JoinWaitlistFlowController>(this),
    new PreferredJobCategoryFormPageActionController<JoinWaitlistFlowController>(
      this
    ),
    new IsWorkingFormPageActionController<JoinWaitlistFlowController>(this),
    new FresherJoinReasonFormPageActionController<JoinWaitlistFlowController>(
      this
    ),
    new ExperiencedJoinReasonFormPageActionController<JoinWaitlistFlowController>(
      this
    ),
    new DiscordPageActionController<JoinWaitlistFlowController>(this),
    new WaitlistConfirmationPageActionController<JoinWaitlistFlowController>(
      this
    ),
    new ReturnOriginPageActionController<JoinWaitlistFlowController>(this),
  ];

  constructor(flowData: FlowDataType) {
    super(flowData);
  }

  private checkIfWaitlistProfileCompleted = () => {
    const userDetails = ClientAppManager.instance.userDetailsManager.user;

    const { userJobProfile, userPreference } = userDetails;

    const userWaitlistProfileCompleted =
      userJobProfile?.name &&
      userJobProfile?.experience != null &&
      userJobProfile?.experience >= 0 &&
      userJobProfile?.gaming_job_experience != null &&
      userJobProfile?.gaming_job_experience >= 0 &&
      userJobProfile?.phone_number &&
      userJobProfile?.user_form_data?.is_currently_working != undefined &&
      (userJobProfile?.job_category || userPreference?.job_category) &&
      userJobProfile?.user_form_data?.course_joining_reason;

    return userWaitlistProfileCompleted ? true : false;
  };

  override isFlowRequired(): boolean {
    const { waitlistedCourses } = this.flowData || {};
    const excludeCourse = flowExcludeCourses.includes(waitlistedCourses[0]);

    if (excludeCourse) {
      return false;
    }

    let isWaitlistProfileCompleted = this.checkIfWaitlistProfileCompleted();
    return isWaitlistProfileCompleted ? false : true;
  }

  public preFlowStartSetup(): void {
    localStorage.setItem(
      "prev-url",
      PopupService.instance.removePopupQueryFromUrl(
        ClientAppManager.instance.userFlowManager.router.asPath
      )
    );
  }
}

export default JoinWaitlistFlowController;
