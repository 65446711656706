import BaseFlowController from "../../FlowControllers/Baseflow.controller";
import BasePageActionController from "./BasePageAction.controller";

class ReturnOriginPageActionController<
  T extends BaseFlowController
> extends BasePageActionController<T> {
  constructor(flow: T) {
    super(flow);
  }

  protected async takeAction(): Promise<void> {
    this.url =
      localStorage.getItem("prev-url") || "/course/full-stack-game-development";
    await super.takeAction();
    this.actionCallBack();
  }
}

export default ReturnOriginPageActionController;
