import ClientAppManager from "../ClientAppManager";
import UserDetailsManager from "../UserDetailsManager";

declare global {
  interface Window {
    clarity?: {
      (event: string, key: string, value: string): void;
      (
        event: string,
        email: string,
        sessionId: string,
        url: string,
        name: string
      ): void; // 5 params
    };
  }
}

class ClarityController {
  private record: boolean;
  constructor() {
    this.record =
      typeof window != "undefined" && window.location.host.includes("outscal");
  }

  public loaded() {}

  public identifyUser(
    userDetailsManager: UserDetailsManager,
    data: { [Event: string]: string | number }
  ) {
    if (!this.record) return;

    window.clarity(
      "identify",
      userDetailsManager?.user?.userDetails?.email,
      ClientAppManager.instance.sessionId,
      window.location.pathname,
      userDetailsManager?.user?.userDetails?.email ||
        userDetailsManager?.anonymousUserId
    );
    if (data) {
      this.captureUserEvent(data);
    }
  }

  public captureUserEvent(data: { [Event: string]: string | number }) {
    if (!this.record) return;

    Object.keys(data).forEach((eventName) => {
      window.clarity?.("set", eventName, data[eventName].toString());
    });
  }

  public captureEvent(
    eventName: string,
    data: { [Event: string]: string | number }
  ) {
    if (!this.record) return;

    // showLabel = label ? "label: " + label : "";
    //
    // window.clarity(
    //     "set",
    //     category || "Click",
    //     eventName + "_" + action + showLabel
    //   );
    // Object.keys(data).forEach((eventName) => {
    //   window.clarity?.("set", eventName, data[eventName].toString());
    // });
    // window.clarity?.("set", eventName, "");
  }

  public startSessionRecording() {}
}

export default ClarityController;
