import { useDispatch, useSelector } from "react-redux";
import {
  setProgress,
  clearProgress,
} from "@/store/reducers/lmsProgressReducer";

const useLmsProgressActions = () => {
  const dispatch = useDispatch();
  const lmsProgress = useSelector((state) => state.lmsProgress);
  const setLMSProgress = (course) => {
    dispatch(setProgress(course));
  };
  const clearLMSProgress = () => {
    dispatch(clearProgress());
  };

  return {
    setLMSProgress,
    clearLMSProgress,
    lmsProgress,
  };
};

export default useLmsProgressActions;
