import dynamic from "next/dynamic";
import "@/styles/globals.css";
import "@/styles/Collapsible.css";
import "@/styles/ImageNode.css";
import "@/styles/styles.scss";
import "../public/css/courseBootstrap.css";
import "../public/css/courseSwiper.css";

import { ApolloProvider } from "@apollo/client/react";
import { ThemeProvider } from "styled-components";
import { Theme } from "@/outscal-commons-frontend/Styled/Theme";
import { Provider } from "react-redux";
import store from "../store/store";

import { useEffect, useState } from "react";
import { useAmp } from "next/amp";
import { useRouter, Router } from "next/router";
import { PostHogProvider, usePostHog } from "posthog-js/react";

import UseAnalyticsClickEvent from "@/outscal-commons-frontend/Hooks/useClickAnalytics";
import ApolloClientManager from "../Logic/GraphQL/ApolloClientManager";
import MobileNavbarService from "@/mvcComponents/Commons/Navbar/MobileNavbar/mobileNavbar.service";
import UseElementVisibilityAnalytics from "@/mvcComponents/CommonUI/Hooks/useElementVisibilityAnalytics";
import ClientAppManager from "@/Logic/ClientLogic/Services/ClientAppManager";
import useScrollBarVisibility from "@/hooks/useScrollbarVisibility";
import UseComponentVisibility from "@/hooks/useComponentVisibility";
import { isMobile } from "@/hooks/useMobileBreakPoint";
import RevenueStrip from "@/mvcComponents/CommonUI/Cards/RevenueStrip/RevenueStrip.controller";

const PopupService = dynamic(() => import("@/Services/popupService"), {
  ssr: false,
});
const Tooltip = dynamic(
  () => import("react-tooltip").then((mod) => mod.Tooltip),
  { ssr: false }
);
const Toast = dynamic(() => import("@/outscal-commons-frontend/Styled/Toast"), {
  ssr: false,
});
const Header = dynamic(() => import("@/PageComponents/Header/Header"));

ClientAppManager.getInstance();

export const config = { amp: "hybrid" };

function App({ Component, pageProps }) {
  const isAmp = useAmp();
  const posthog = usePostHog();
  const [showHeader, setShowHeader] = useState({ data: [] });
  const router = useRouter();

  useEffect(() => {
    ClientAppManager.instance.initialize();
    ClientAppManager.instance.userFlowManager.router = router;
    ClientAppManager.instance.appMounted();

    if (!sessionStorage.getItem("sessionId")) {
      sessionStorage.setItem("sessionId", ClientAppManager.instance.sessionId);
    } else {
      ClientAppManager.instance.sessionId = sessionStorage.getItem("sessionId");
    }

    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
      api_host:
        process.env.NEXT_PUBLIC_POSTHOG_HOST || "https://us.i.posthog.com",
      person_profiles: "always",
      disable_session_recording: true,
      loaded: (posthog) => {
        ClientAppManager.instance.analyticsService.loaded(false, posthog);
      },
    });

    const handleRouteChange = () => posthog?.capture("$pageview");
    Router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      Router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, []);

  useEffect(() => {
    if (
      (window.innerWidth < 600 && router.asPath.includes("job=")) ||
      router.asPath.includes("popup=42")
    ) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    setShowHeader(
      ClientAppManager.instance.pageManager?.pageData?.showNavbarOn
    );
  }, [router.asPath]);

  const PATHS_TO_SHOW_SCROLLBAR = ["/welcome/ftue1", "/community/*"];
  const PATHS_TO_EXCLUDE_SCROLLBAR = ["/community/*-ads"];
  useScrollBarVisibility(PATHS_TO_SHOW_SCROLLBAR, PATHS_TO_EXCLUDE_SCROLLBAR);

  return (
    <PostHogProvider client={posthog}>
      {isAmp ? (
        <Component {...pageProps} />
      ) : (
        <ThemeProvider theme={Theme}>
          <ApolloProvider client={ApolloClientManager.client}>
            <Provider store={store}>
              <UseComponentVisibility conditions={showHeader}>
                <Header />
              </UseComponentVisibility>

              <Component {...pageProps} />

              <UseComponentVisibility conditions={showHeader}>
                <MobileNavbarService />
              </UseComponentVisibility>

              <Toast />
              <Tooltip id="data-tip" className="generic-tooltip" />
              <PopupService />
            </Provider>
            <UseAnalyticsClickEvent />
            <UseElementVisibilityAnalytics />
          </ApolloProvider>
        </ThemeProvider>
      )}
    </PostHogProvider>
  );
}

export default App;
