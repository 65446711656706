import ClientAppManager from "../../ClientAppManager";
import BaseActionController from "../FlowActionControllers/BaseAction.controller";
import { FlowDataType } from "../UserFlowService.types";

class BaseFlowController {
  private nextAction: number = JSON.parse(
    sessionStorage.getItem("nextPotentialAction") || "0"
  );
  protected actions: BaseActionController<BaseFlowController>[] = [];
  public flowData: FlowDataType;

  constructor(flowData: FlowDataType) {
    this.flowData = flowData;
  }

  public isFlowRequired(): boolean {
    return true;
  }

  public async takeAction(): Promise<void> {
    let hasTakenAction = false;

    let checkingAction = this.nextAction;

    if (this.nextAction >= this.actions.length) {
      return ClientAppManager.instance.userFlowManager?.endFlow();
    }

    do {
      let potentialAction = this.actions[checkingAction];
      hasTakenAction = await potentialAction?.checkAndTakeAction();

      checkingAction++;
      sessionStorage.setItem(
        "nextPotentialAction",
        JSON.stringify(checkingAction)
      );
      this.nextAction = checkingAction;
    } while (!hasTakenAction && checkingAction < this.actions.length);

    if (this.nextAction >= this.actions.length) {
      ClientAppManager.instance.userFlowManager?.endFlow();
    }
  }

  public preFlowStartSetup(): void {}
}

export default BaseFlowController;
