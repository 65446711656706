import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers/reducers";
import LocalStorageManager from "@/Logic/ClientLogic/Services/LocalStorageManager";

const store = configureStore({
  reducer: (state, action) => {
    const newState = rootReducer(state, action);
    let token = null;
    const user = LocalStorageManager.get("userData");

    if (user) {
      const parsedUser = JSON.parse(user);
      token = parsedUser.token;
    }

    if (!newState.auth?.token && token) {
      newState.auth = {
        token,
      };
    }

    if (!newState.popup) {
      const urlParams = new URLSearchParams(window.location.search);
      newState.popup = {
        popup: urlParams.get("popup") ? urlParams.get("popup") : null,
        data: null,
      };
    }

    return newState;
  },
});

export default store;
